import Anchor from "@10xdev/ui/src/Anchor";
import Banner from "@10xdev/ui/src/Banner";
import Block from "@10xdev/ui/src/Blocks/_Block";
import Head from "@10xdev/ui/src/Head";
import Heading from "@10xdev/ui/src/Heading";
import Hero from "@10xdev/ui/src/Hero";
import HeroHeading from "@10xdev/ui/src/HeroHeading";
import Main from "@10xdev/ui/src/Main";
import Paragraph from "@10xdev/ui/src/Paragraph";
import { layoutWidth878 } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { GetStaticProps, NextPage } from "next";
import { Fragment } from "react";

import { Locale } from "@10xdev/cms/types";
import { GlobalFooter } from "@10xdev/ui/src/Footer";
import { stripUndefineds } from "../../utils";

interface Props {
  locale: Locale;
}

const title = "Community";

const CommunityRedirect: NextPage<Props> = ({ locale }) => {
  return (
    <>
      <Head description={""} title={title} />
      <Banner />

      <Main background={"#f7f9fc"}>
        <Hero>
          <HeroHeading>{title}</HeroHeading>
        </Hero>

        <Block>
          <div
            className={"CommunityRedirect"}
            css={css`
              margin: 0 auto;
              max-width: ${layoutWidth878};
              width: 100%;
            `}
          >
            <Paragraph size={"medium"}>
              {[
                "Thanks for sharing your knowledge with the community over the past few years.",
                "We are retiring the forums as we work towards an updated digital experience.",
                "Below you can find some helpful resources. If you need to, you can always reach",
                "out for technical support at ",
              ].join(" ")}
              <Anchor href={"mailto:support@10xgenomics.com"} target={"_blank"}>
                {"support@10xgenomics.com."}
              </Anchor>
            </Paragraph>

            {[
              {
                description: "Our latest updates, tips, and tricks.",
                heading: "10x Genomics Blog",
                href: "/blog",
              },
              {
                description: "Protocols created by customers of 10x Genomics.",
                heading: "Customer Developed Protocols",
                href: "/customer-developed-protocols",
              },
              {
                description: "FAQs about our products.",
                heading: "Knowledge Base",
                href: "https://kb.10xgenomics.com/hc/en-us",
              },
              {
                description:
                  "Demonstrated protocols, user guides, software, and datasets.",
                heading: "Support site",
                href: "/support",
              },
            ].map((item) => {
              const { description, heading, href } = item;
              return (
                <Fragment key={heading}>
                  <Heading
                    as={"h3"}
                    css={css`
                      margin-bottom: 8px;
                    `}
                    size={"medium"}
                  >
                    <Anchor href={href}>{heading}</Anchor>
                  </Heading>
                  <Paragraph size={"medium"}>{description}</Paragraph>
                </Fragment>
              );
            })}
          </div>
        </Block>
      </Main>

      <GlobalFooter locale={locale} />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: stripUndefineds({
      locale,
    }),
  };
};

export default CommunityRedirect;
