import { colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  background?: string;
  children: ReactNode;
  className?: string;
}

const Main: FunctionComponent<Props> = ({
  background,
  children,
  className = "",
}) => {
  return (
    <main
      className={className}
      css={css`
        background: ${background || colorWhite};
        min-height: 880px;
        overflow-x: hidden;
      `}
    >
      {children}
    </main>
  );
};

export default Main;
