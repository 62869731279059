import { colorSteelDarkest, layoutBannerHeight } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Block from "../Blocks/_Block";
import Branding from "../Branding";
import Navigation from "../Navigation";
import { useRouter } from "next/router";

interface Props {
  /** CSS background, defaults to `colorSteelDarkest`. */
  background?: string;

  /**
   * Specifies whether to use a light or dark logo.
   */
  mode?: "light" | "dark";
}

const Banner: FunctionComponent<Props> = ({ background, mode }) => {
  const { locale } = useRouter();
  return (
    <Block
      as={"header"}
      background={background || colorSteelDarkest}
      role={"banner"}
    >
      <div
        css={css`
          align-items: center;
          box-sizing: border-box;
          display: flex;
          gap: 56px;
          height: ${layoutBannerHeight};
          margin: 0 auto;
          max-width: 1200px;
          padding: 1rem 0;
          width: 100%;
        `}
      >
        <Branding mode={mode} />
        <Navigation locale={locale} mode={mode} />
      </div>
    </Block>
  );
};

export default Banner;
