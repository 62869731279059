import {
  colorBlueLightest,
  layoutWidth878,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
  wide?: boolean;
}

const Hero: FunctionComponent<Props> = ({ children, wide }) => {
  return (
    <div
      css={css`
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        min-height: 192px;
        margin: 0 auto;
        max-width: ${wide ? layoutWidth1200 : layoutWidth878};
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          background: ${colorBlueLightest};
          display: none;
          min-height: 0;
          padding: 1.5rem 1.5rem 1rem;

          :first-of-type {
            display: block;
          }
        }
      `}
    >
      {children}
    </div>
  );
};

export default Hero;
