import type { TextColor } from "@10xdev/types";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Anchor from "../Anchor";
import Text from "../Text";

interface Props {
  children: ReactNode;
  color?: TextColor;
  href?: string;
}

const HeroHeading: FunctionComponent<Props> = ({ children, color, href }) => {
  return (
    <Text
      as={"h1"}
      color={color}
      css={css`
        margin: 0;
      `}
      responsive={true}
      size={"xxxlarge"}
      weight={"semibold"}
    >
      {href ? (
        <Anchor color={"inherit"} href={href}>
          {children}
        </Anchor>
      ) : (
        <>{children}</>
      )}
    </Text>
  );
};

export default HeroHeading;
