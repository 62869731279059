import {
  mediaTabletLandscape,
  sizeMedium,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { CSSProperties, FunctionComponent, Ref } from "react";
import { forwardRef } from "react";

interface Props {
  as?: string;
  background?: string;
  children?: any;
  className?: string;
  id?: string;
  ref?: Ref<any>;
  role?: string;
  style?: CSSProperties;
  useHorizontalPaddingAtLowerWidths?: boolean;
}

// https://github.com/yannickcr/eslint-plugin-react/issues/3015
const Block: FunctionComponent<Props> = forwardRef((props, ref) => {
  const {
    as = "section",
    background = null,
    children,
    className,
    id,
    role = null,
    style,
    useHorizontalPaddingAtLowerWidths = true,
  } = props;
  const Element = as;

  return (
    // @ts-expect-error No property children
    <Element
      className={className}
      css={css`
        background: ${background || "none"};
        box-sizing: border-box;
        margin: 0;
        padding: 0 ${sizeXlarge};
        width: 100%;

        @media (max-width: ${mediaTabletLandscape}) {
          padding: ${useHorizontalPaddingAtLowerWidths ? `0 ${sizeMedium}` : 0};
        }
      `}
      id={id}
      ref={ref}
      role={role}
      style={style}
    >
      {children}
    </Element>
  );
});

export default Block;
